import React, {useState} from 'react'
import {SwitchTransition, Transition} from 'react-transition-group'
import Helmet from 'react-helmet'
import Header from './Header'
import '../styles/main.scss'

export const TRANSITION_DURATION = 400

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
}

const Layout = ({children, pageContext = {}, location}) => {
  const {header = {}} = pageContext

  const siteFragment = (
    <React.Fragment>
      <SwitchTransition>
        <Transition
          key={location.pathname}
          mountOnEnter
          unmountOnExit
          appear
          timeout={TRANSITION_DURATION}
        >
          {status => (
            <div
              id="maincontent"
              className="layout-wrapper"
              style={{
                ...TRANSITION_STYLES.default,
                ...TRANSITION_STYLES[status],
              }}
            >
              <Header {...header} />
              <main>{children}</main>
            </div>
          )}
        </Transition>
      </SwitchTransition>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {siteFragment}
    </React.Fragment>
  )
}

export default Layout
