import React from 'react'
import {Link} from 'gatsby'
import {handleLinkClick} from '../utils/nav'

const SmartLink = ({
  title,
  url,
  openInNewWindow,
  children,
  onClick = () => {},
  ...props
}) => {
  if (url[0] === '/') {
    return (
      <Link
        {...props}
        title={title}
        target={openInNewWindow ? '_blank' : null}
        rel={openInNewWindow ? 'noopener noreferrer' : null}
        to={`${url}`}
        onClick={e => {
          handleLinkClick(url, e)
          onClick()
        }}
      >
        {children || title}
      </Link>
    )
  } else {
    return (
      <a
        {...props}
        title={title}
        target={openInNewWindow ? '_blank' : null}
        rel={openInNewWindow ? 'noopener noreferrer' : null}
        href={url}
      >
        {children || title}
      </a>
    )
  }
}

export default SmartLink
