import scrollToElement from 'scroll-to-element'
import {withPrefix} from 'gatsby'

export const isBrowser = typeof window !== 'undefined'

export function scroller(target, offset = 0, duration = 1000) {
  scrollToElement(target, {
    duration,
    offset,
  })
}

export function handleLinkClick(to, e, onAnchorLinkClick) {
  if (isBrowser && to.includes('#')) {
    const [anchorPath, anchor] = to.split('#')
    if (window.location.pathname === withPrefix(anchorPath)) {
      e.preventDefault()
      scroller(`#${anchor}`)
    }
  }

  if (onAnchorLinkClick) onAnchorLinkClick()
}
