import React, {Component} from 'react'
import Link from './Link'
import Reveal from './Reveal'

const TRANSITION_DURATION = 600

const DEFAULT_STYLE = {
  transition: `${TRANSITION_DURATION}ms opacity ease`,
  opacity: 0,
}

const TRANSITION_STYLES = {
  entering: {opacity: 0},
  entered: {opacity: 1},
  exited: {
    opacity: 0,
  },
  exiting: {
    opacity: 0,
  },
}

const INITIAL_STATE = {
  isOpen: false,
}

class Header extends Component {
  constructor() {
    super()

    this.state = {...INITIAL_STATE}
  }

  closeDropdown = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const {leftLink = {}, rightLink = {}} = this.props

    return (
      <nav className="header bb--black z3 psy top bg--white">
        <div className="grid-container">
          <div className="df jcb aic row">
            <div className="col c12">
              <Reveal className="df jcb aic" threshold={100}>
                <p className="sans--sm--md pt3 pb3 sans--xs reveal__fade">
                  {leftLink.title}
                </p>
                <Link {...rightLink} className="hover-opacity link-contact">
                  <p className="sans--sm--md sans--xs pt3 pb3 reveal__fade">
                    {rightLink.title}
                  </p>
                </Link>
              </Reveal>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
